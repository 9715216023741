<template>
  <PopWidget :isShow="isShowPopWidget">
    <template v-slot:title>
      <img class="l-t-title" v-if="!aliplay"
        src="../../assets/geren/bank-info-title.png" />
      <img v-else class="l-t-title" 
        src="../../assets/geren/alipay-info-title.png" />
    </template>
    <template v-slot:content>
      <div class="l-c-w-i" v-if="!aliplay">
        <div class="bank_field flex">
          <div class="label">账户姓名：</div>
          <div>{{ bank.bank_username }}</div>
        </div>
        <div class="bank_field flex">
          <div class="label">银行账号：</div>
          <div>{{ bank.bank_account }}</div>
        </div>
        <div class="bank_field flex">
          <div class="label">开户银行：</div>
          <div>{{ bank.bank_name  }}</div>
        </div>
        <div class="bank_field flex">
          <div class="label">银行属地：</div>
          <div>{{ bank.bank_address }}</div>
        </div>
      </div>
      <div class="l-c-w-i" v-if="aliplay">
        <div class="bank_field flex">
          <div class="label">支付宝账号：</div>
          <div>{{ aliplay.alipay }}</div>
        </div>
        <div class="bank_field flex">
          <div class="label">实名制姓名：</div>
          <div>{{ aliplay.name }}</div>
        </div>
      </div>
      <img @click="confirmClick()"
        class="l-confirm-btn" src="../../assets/login/confirm-btn.png" />

      <div class="l-c-w-i">
        <div class="bank_field flex">
          <div style="width:.84rem;height:1px;"></div>
          <div style="font-size:.12rem;text-align: center;color:#897561;">
            {{ `温馨提示：如需修改绑定${aliplay?'支付宝':'银行卡'}，请联系客服` }}
          </div>
        </div>
      </div>

    </template>
  </PopWidget>
</template>
<script>
import { mapMutations } from 'vuex'
import PopWidget from '../common/PopWidget.vue'

export default {
  props:{
    aliplay:Object,
    bank:{},
    isShow:Number,
  },
  data(){
    return {
      isShowPopWidget:0,
    }
  },
  methods:{
    ...mapMutations(['popMsg']),
    confirmClick(){
      this.isShowPopWidget = 0
    }
  },
  watch:{
    isShow(n,o){
      this.isShowPopWidget = n;
    }
  },
  components:{
    PopWidget
  }
}
</script>
<style scoped lang="stylus">
.l-t-title
  width 2.14rem
  margin-top -.04rem
.l-confirm-btn
  width .94rem
  margin-top .16rem
  transition transform .3s
  &:active
    transform scale(.8)
.l-c-w-i
  display flex
  justify-content center
  flex-direction column
  width 100%
  border-top 1px solid transparent
  padding 0 .2rem
  .bank_field
    margin .15rem 0 0 0
    align-items center
    .label
      padding-left .7rem
      font-size .16rem
</style>